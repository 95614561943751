import { DEFAULT_LOCALE } from '@/lib/constants';
import { lookupKeyboardLayout } from '@/lib/keyboard-layout';
import {
  KeyboardLayoutLookup,
  Locale,
  LocaleKeyboardLayoutLookup,
  LocaleLookup,
} from '@/types/export';
import { GqlKeyboardLayout } from '@/types/graphql';

export function lookupLocale<T>(locale: Locale, lookup: LocaleLookup<T>) {
  return lookup?.[locale] ?? lookup?.[DEFAULT_LOCALE];
}

export function lookupLocaleKeyboardLayout<T>(
  locale: Locale,
  keyboardLayout: GqlKeyboardLayout,
  lookup: LocaleKeyboardLayoutLookup<T>,
): T {
  const lookedupLocale = lookupLocale(locale, lookup);

  if (
    (lookedupLocale as KeyboardLayoutLookup<T>)?.[GqlKeyboardLayout.QWERTY] ||
    (lookedupLocale as KeyboardLayoutLookup<T>)?.[GqlKeyboardLayout.AZERTY]
  )
    return lookupKeyboardLayout(
      keyboardLayout,
      lookedupLocale as KeyboardLayoutLookup<T>,
    );

  return lookedupLocale as T;
}
