<template>
  <div class="sb_modal-error">
    <div class="sb_modal-error_title">Er ging iets mis.</div>
    <p v-if="showContactInfo">
      Als dit vaker gebeurt neem dan contact op met
      <a href="mailto:support@typetuin.nl" target="_blank"
        >support@typetuin.nl</a
      >.
    </p>

    <div v-if="error" class="sb_modal-error_error">
      {{ error }}
    </div>

    <div class="sb_modal-error_buttons">
      <ButtonPill button-type="primary" @click="$emit('close')">
        Sluiten
        <SvgIcon icon-id="icon_close" />
      </ButtonPill>
    </div>
  </div>
</template>

<script>
import ButtonPill from '@/components/ButtonPill';

export default {
  name: 'ModalError',
  components: {
    ButtonPill,
  },
  props: {
    error: {
      type: String,
      required: true,
    },
    showContactInfo: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_modal-error {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 60px;
  background: url('/public/static/img/bg/modal.png');
  background-size: 100% 100%;

  a:not(.sb_button) {
    color: $default-text-color;
    text-decoration: underline;
  }

  &_error {
    padding: 20px;
    font-family: 'Ubuntu Mono', monospace;
    background: $brand-lightest-gray;
    border: solid 1px $brand-light-gray;
    border-radius: 4px;
  }

  &_title {
    margin: 0 0 20px;
    font-family: Budidaya, Arial, Helvetica, sans-serif;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 1px;
  }

  &_buttons {
    margin: 40px 0 0;

    .sb_button {
      margin: 0 20px 0 0;
    }
  }
}
</style>
