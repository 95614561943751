export enum GqlKeyboardLayout {
  'QWERTY' = 'QWERTY',
  'AZERTY' = 'AZERTY',
}

export enum GqlTimeSeriesBucket {
  'Day' = 'Day',
  'Week' = 'Week',
  'Month' = 'Month',
  'Year' = 'Year',
}

export type GqlDateTime = string;
export type GqlDecimal = number;

export type GqlUser = {
  id: string;
  username: string;
  email: string;
  createdAt?: GqlDateTime;
  emailConfirmedAt?: GqlDateTime;
  startDate?: GqlDateTime;
  endDate?: GqlDateTime;
  educationGroup: GqlEducationGroup;
  secondaryEmails: string[];
  firstName: string;
  prefix?: string;
  lastName: string;
  birthDate?: GqlDateTime;
  phone?: string;
  mobile?: string;
  gender?: GqlGender;
  registrationNumber?: number;
  mostRecentLoginDate?: GqlDateTime;
  roles: GqlRole[];
  mostRecentRole?: GqlRole;
  completedGames: GqlGame[];
  keyboardLayout: GqlKeyboardLayout;
  typetuin?: GqlGameUser;
  beheer?: GqlBeheerUser;
  hasExtendedLicense: boolean;
  followingCourseGroup?: GqlCourseGroup;
  coachedCourseGroups: GqlCourseGroupSearchResult;
  mostRecentResource?: GqlMostRecentResource;
  license?: GqlLicense;
  licenseStatus: GqlLicenseStatus;
  students: GqlUsersFilterMessage;
  schools: GqlSchool[];
  schoolDistricts: GqlSchoolDistrict[];
  partners: GqlPartner[];
  note?: GqlNote;
  sentMessages: GqlMessageConnection;
  receivedMessages: GqlMessageConnection;
  unreadMessageCounts: number;
  sentMessagesCount: number;
  lastMessageSentAt: GqlDateTime;
};

export type GqlGameUser = {
  id: string;
  createdAt: GqlDateTime;
  updatedAt: GqlDateTime;
  startedAt: GqlDateTime;
  userId: string;
  userLevels: GqlUserLevel[];
  kwouwselUpgrades: GqlGameUserKwouwselUpgrade[];
  kwouwselAccessories: GqlGameUserKwouwselAccessory[];
  gamename?: string;
  charactersPerMinuteStandard: number;
  diamonds: number;
  earnedDiamonds: number;
  highestCups: number;
  fontType: GqlFontType;
  focusMode: boolean;
  hideDiamonds: boolean;
  showInterpunct: boolean;
  showPracticeTimeTimer: boolean;
  introductionCompleted: boolean;
  audioMuted: boolean;
  firstSuccessfulTestExam?: GqlChallengeResult;
  lastSuccessfulTestExam?: GqlChallengeResult;
  examRequests?: GqlChallengeRequest[];
  examResults?: GqlChallengeResult[];
  testExamResults?: GqlChallengeResult[];
  hasRequestedDiploma: boolean;
  hasRequestedParticipationCertificate: boolean;
  lastPlay?: GqlChallengeResult;
  displayedAlfredoMessages?: GqlAlfredoMessageGameUser[];
  gameCompleted: boolean;
  gameCompletedDate?: GqlDateTime;
  participationCertificateIsSent: boolean;
  diplomaIsSent: boolean;
  examState: GqlExamState;
  usePersonalLevelSettings: boolean;
  averageAccuracy?: number;
  averageCharactersPerMinute?: number;
  allowedToRequestDiploma: boolean;
  allowedToRequestParticipationCertificate: boolean;
  notDisplayedAlfredoMessages?: GqlAlfredoMessage[];
  practiceTime: GqlPracticeTimeBucket[];
  miniGamesStates: GqlMiniGamesStates;
  examOpeningDayCountDown: number;
  stars?: number;
  amountOfChallengesToCalculateStars?: number;
};

export type GqlChallengeRequest = {
  id: string;
  createdAt: GqlDateTime;
  exercise: GqlExercise;
  challenge: GqlChallenge;
  ignored: boolean;
};

export type GqlChallengeResult = {
  id: string;
  createdAt: GqlDateTime;
  updatedAt: GqlDateTime;
  challengeRequest: GqlChallengeRequest;
  responseTimePerCharacterInMs: GqlJson;
  enteredText: string;
  timeInMs: number;
  durationSinceRequest: number;
  startTime: GqlDateTime;
  accuracy: number;
  charactersPerMinute: number;
  charactersPerMinuteStandard: number;
  passed: boolean;
  cupsEarned?: number;
  diamondsEarned?: number;
};

export type GqlExercise = {
  id: string;
  createdAt: GqlDateTime;
  updatedAt: GqlDateTime;
  level: GqlLevel;
  user: GqlGameUser;
  numberOfChallenges: number;
  extraDiamonds?: number;
  accuracy?: number;
  charactersPerMinute?: number;
  challengeRequests?: GqlChallengeRequest[];
};

export type GqlLevel = {
  id: string;
  allowBackspace: boolean;
  diamonds: number;
  name: string;
  lives: number;
  timeInMs: number;
};

export type GqlUserLevel = {
  id: string;
  level: GqlLevel;
  unlocked?: boolean;
  user?: GqlGameUser;
  state: GqlUserLevelState;
  firstTime: boolean;
  showedUnlockAnimation: boolean;
};

export type GqlPracticeTimeBucket = {
  from: GqlDateTime;
  to: GqlDateTime;
  duration: GqlDecimal;
};

export type GqlUserLevelState = unknown;
export type GqlJson = unknown;
export type GqlChallenge = unknown;
export type GqlAlfredoMessageGameUser = unknown;
export type GqlExamState = unknown;
export type GqlAlfredoMessage = unknown;
export type GqlMiniGamesStates = unknown;
export type GqlFontType = unknown;
export type GqlEducationGroup = unknown;
export type GqlGender = unknown;
export type GqlRole = unknown;
export type GqlGame = unknown;
export type GqlGameUserKwouwselUpgrade = unknown;
export type GqlGameUserKwouwselAccessory = unknown;
export type GqlBeheerUser = unknown;
export type GqlCourseGroup = { id: string };
export type GqlCourseGroupSearchResult = unknown;
export type GqlMostRecentResource = unknown;
export type GqlLicense = unknown;
export type GqlLicenseStatus = unknown;
export type GqlUsersFilterMessage = unknown;
export type GqlSchool = unknown;
export type GqlSchoolDistrict = unknown;
export type GqlPartner = unknown;
export type GqlNote = unknown;
export type GqlMessageConnection = unknown;
