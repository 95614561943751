<template>
  <div>
    <slot />
  </div>
</template>
<script>
import * as Sentry from '@sentry/vue';

export default {
  name: 'ErrorBoundary',
  props: {
    stopPropagation: {
      type: Boolean,
      default: false,
    },
  },
  errorCaptured(err, vm, info = '') {
    Sentry.captureException(err, {
      extra: {
        info,
        props: vm.$props,
        data: vm.$data,
      },
    });
    this.$emit('errorCaptured', {
      err,
      vm,
      info,
    });
    if (this.stopPropagation) return false;
    return err;
  },
};
</script>
