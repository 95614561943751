<template>
  <div class="sb_background" :class="{ 's_in-focus-mode': focusMode }">
    <div
      v-if="!focusMode"
      class="sb_background_image-bg"
      :style="{
        transform: 'translate(-50%, -50% ) scale(' + scale + ')',
        ...(backgroundImage
          ? { 'background-image': `url(${backgroundImage})` }
          : {}),
        opacity: backgroundOpacity,
      }" />
  </div>
</template>

<script>
export default {
  name: 'Background',
  props: {
    backgroundImage: {
      type: String,
      // default: '/static/img/bg/default.jpg'
      default: null,
    },
    scale: {
      type: Number,
      default: 1,
    },
    focusMode: {
      type: Boolean,
      default: false,
    },
    backgroundOpacity: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss">
//
//  background
//
.sb_background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @media print {
    display: none;
  }

  &.s_in-focus-mode {
    background: linear-gradient(180deg, $brand-primary, $brand-primary-darkest);
  }
}

//
//  IMAGE BG
//
.sb_background_image-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  width: 2560px;
  height: 1360px;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 64px 64px #000;
}
</style>
