export function date(date: Date, showTime = false) {
  if (!date) return '';

  let result = '';
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // let result = `${day}-${month}-${year} `;

  if (day <= 9) result = `${result}0${day}-`;
  else result = `${result}${day}-`;

  if (month <= 9) result = `${result}0${month}-`;
  else result = `${result}${month}-`;

  result = `${result}${year} `;

  if (showTime) {
    if (hours <= 9) result = `${result}0${hours}`;
    else result = `${result}${hours}`;

    if (minutes <= 9) result = `${result}:0${minutes}`;
    else result = `${result}:${minutes}`;
  }

  return result;
}
