import azertyKeyMap from '@/data/azertyKeys.json';
import qwertyKeyMap from '@/data/qwertyKeys.json';
import { DEFAULT_KEYBOARD_LAYOUT } from '@/lib/constants';
import { KeyboardLayoutLookup } from '@/types/export';
import { GqlKeyboardLayout } from '@/types/graphql';

export function convertKeysFromQwerty(
  keyboardLayout: GqlKeyboardLayout,
  keysInQwerty: string[],
) {
  switch (keyboardLayout) {
    case GqlKeyboardLayout.QWERTY:
    default:
      return keysInQwerty;
    case GqlKeyboardLayout.AZERTY:
      return convertKeyboardKeys(
        GqlKeyboardLayout.QWERTY,
        keyboardLayout,
        keysInQwerty,
      );
  }
}

export function getKeyMap(keyboardLayout: GqlKeyboardLayout): KeyMap {
  switch (keyboardLayout) {
    case GqlKeyboardLayout.QWERTY:
    default:
      return qwertyKeyMap as KeyMap;
    case GqlKeyboardLayout.AZERTY:
      return azertyKeyMap as KeyMap;
  }
}

export function getKey(keyboardLayout: GqlKeyboardLayout, key: string) {
  const keyMap = getKeyMap(keyboardLayout);

  let keyRow = -1,
    keyColumn = -1;

  keyMap.forEach((row, rowIndex) => {
    row.forEach((column, columnIndex) => {
      if (column.default === key) {
        keyRow = rowIndex;
        keyColumn = columnIndex;
      }
    });
  });

  return keyMap?.[keyRow]?.[keyColumn];
}

export function convertKeyboardKeys(
  keyboardLayoutFrom: GqlKeyboardLayout,
  keyboardLayoutTo: GqlKeyboardLayout,
  keysInFrom: string[],
): string[] {
  const keyMapFrom = getKeyMap(keyboardLayoutFrom);
  const keyMapTo = getKeyMap(keyboardLayoutTo);

  const keysInTo = keysInFrom.reduce<string[]>((accumulator, key) => {
    let keyRow = -1,
      keyColumn = -1;

    keyMapFrom.forEach((row, rowIndex) => {
      row.forEach((column, columnIndex) => {
        if (column.default === key) {
          keyRow = rowIndex;
          keyColumn = columnIndex;
        }
      });
    });

    // AZERTY has an extra key on next to the leftShift, so we add an extra to the column index
    const correctedKeyColumn =
      keyboardLayoutTo === GqlKeyboardLayout.AZERTY &&
      keyRow === 3 &&
      keyColumn >= 1
        ? keyColumn + 1
        : keyColumn;

    const toKey = keyMapTo?.[keyRow]?.[correctedKeyColumn]?.default;

    return [...accumulator, toKey];
  }, []);

  return keysInTo;
}

export function lookupKeyboardLayout<T>(
  keyboardLayout: GqlKeyboardLayout,
  lookup: KeyboardLayoutLookup<T>,
): T {
  return lookup?.[keyboardLayout] ?? lookup[DEFAULT_KEYBOARD_LAYOUT];
}
