<template>
  <div class="sb_message-modal">
    <div class="sb_message-modal_title">
      {{ title }}
    </div>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="sb_message-modal_body" v-html="bodyText" />
    <div class="sb_message-modal_buttons">
      <ButtonPill
        v-if="!hidePrimary"
        button-type="primary"
        @click="
          $emit('close');
          primaryCallback();
        ">
        {{ primaryText }}
        <SvgIcon v-if="primaryIcon !== false" :icon-id="primaryIcon" />
      </ButtonPill>
      <ButtonPill
        v-if="!hideSecondary"
        button-type="naked"
        @click="
          $emit('close');
          secondaryCallback();
        ">
        {{ secondaryText }}
        <SvgIcon v-if="secondaryIcon !== false" :icon-id="secondaryIcon" />
      </ButtonPill>
    </div>
  </div>
</template>

<script>
import ButtonPill from '@/components/ButtonPill';

export default {
  name: 'ModalError',
  components: {
    ButtonPill,
  },
  props: {
    secondaryCallback: {
      type: Function,
      default: () => {
        return;
      },
    },
    primaryCallback: {
      type: Function,
      default: () => {
        return;
      },
    },
    title: {
      type: String,
      default: '',
    },
    bodyText: {
      type: String,
      default: '',
    },
    hidePrimary: {
      type: Boolean,
      default: false,
    },
    primaryText: {
      type: String,
      default: 'Sluiten',
    },
    primaryIcon: {
      type: [String, Boolean],
      default: false,
    },
    secondaryText: {
      type: String,
      default: 'Doorgaan',
    },
    secondaryIcon: {
      type: [String, Boolean],
      default: false,
    },
    hideSecondary: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_message-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 60px;
  background: url('/public/static/img/bg/modal.png');
  background-size: 100% 100%;

  a:not(.sb_button) {
    color: $default-text-color;
    text-decoration: underline;
  }

  &_title {
    margin: 0 0 20px;
    font-family: Budidaya, Arial, Helvetica, sans-serif;
    font-size: 40px;
    font-weight: 700;
    color: $brand-on-book;
    letter-spacing: 1px;
  }

  &_body {
    font-size: 24px;
    color: $brand-on-book;
  }

  &_buttons {
    margin: 40px 0 0;

    .sb_button {
      margin: 0 20px 20px 0;
    }
  }
}
</style>
