import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueRouter, { NavigationGuard, RouteConfig } from 'vue-router';
import multiguard from 'vue-router-multiguard';
import { onLogout, onLogin } from '@/apollo';
import { DEMO_STUDENT_ROLE, LEVEL_INTRODUCTIONS } from '@/lib/constants';
import { getAccessToken, getRole } from '@/lib/util-helper';
import {
  examTriesGuard,
  examGuard,
  examMaximumTriesGuard,
  testExamGuard,
} from './guards';

Vue.use(VueRouter);
Vue.use(VueMeta);

export const START_ROUTE_NAME = 'Start';
export const LOGIN_ROUTE_NAME = 'Login';

const generalGuard: NavigationGuard = async (to, from, next) => {
  // Prohibit directly landing to Exam
  if (to.name === 'Exam Examination' && from.name !== 'Exam Ready')
    return next({
      name: START_ROUTE_NAME,
    });

  // prohibit users from "previewing" other levels
  if (
    to.path.includes('/level/') &&
    to.params?.id &&
    from.params?.id &&
    to.params.id !== from.params.id
  )
    return next({
      name: START_ROUTE_NAME,
    });

  // Handle impersonation
  if (to.query.impersonate) {
    await onLogout();
    await onLogin(String(to.query.impersonate));
    return next({
      name: START_ROUTE_NAME,
    });
  }

  const isLoggedIn = !!getAccessToken();

  // If a route is not allowed to be viewed by authorized, direct to overview
  if (isLoggedIn && to.meta?.disallowAuthorized)
    return next({ name: START_ROUTE_NAME });

  // If a route is allowed for unauthorized, direct to that route
  if (to.meta?.allowUnauthorized) return next();

  // Only allow demo user to access certain routes
  if (getRole() === DEMO_STUDENT_ROLE) {
    if (to.meta?.allowDemoUser) return next();
    // logout
    await onLogout();
    return next({
      name: LOGIN_ROUTE_NAME,
    });
  }

  // If the route needs a token and none is set, redirect to login
  if (!isLoggedIn) {
    // logout
    await onLogout();
    return next({
      name: LOGIN_ROUTE_NAME,
    });
  }

  return next();
};

export const routes: RouteConfig[] = [
  {
    path: '/',
    name: START_ROUTE_NAME,
    redirect: {
      name: 'Overview',
    },
    beforeEnter: multiguard([generalGuard]),
  },
  {
    path: '/messages',
    name: 'Messages',
    component: () =>
      import(
        /* webpackChunkName: "Messages" */ '@/views/Messages/MessagesOverview.vue'
      ),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      background: {
        backgroundImage: '/static/img/bg/book_page-left.jpg',
      },
      allowDemoUser: true,
    },
  },
  {
    path: '/message/:messageId',
    name: 'MessageDetail',
    component: () =>
      import(
        /* webpackChunkName: "Messages" */ '@/views/Messages/MessageDetail.vue'
      ),
    beforeEnter: multiguard([generalGuard]),
    props: true,
    meta: {
      background: {
        backgroundImage: '/static/img/bg/bg_message.jpg',
      },
    },
  },
  {
    path: '/message-compose',
    name: 'MessageCompose',
    component: () =>
      import(
        /* webpackChunkName: "Messages" */ '@/views/Messages/MessageCompose.vue'
      ),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      background: {
        backgroundImage: '/static/img/bg/bg_message.jpg',
      },
    },
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: () =>
      import(
        /* webpackChunkName: "PasswordReset" */ '@/views/PasswordReset.vue'
      ),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      allowUnauthorized: true,
      disallowAuthorized: true,
      background: {
        backgroundImage: '/static/img/bg/default.jpg',
      },
    },
  },
  {
    path: '/forgot-username',
    name: 'ForgotUsername',
    component: () =>
      import(
        /* webpackChunkName: "PasswordReset" */ '@/views/ForgotUsername.vue'
      ),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      allowUnauthorized: true,
      disallowAuthorized: true,
      background: {
        backgroundImage: '/static/img/bg/default.jpg',
      },
    },
  },

  {
    path: '/new-password',
    name: 'NewPassword',
    component: () =>
      import(/* webpackChunkName: "PasswordReset" */ '@/views/NewPassword.vue'),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      allowUnauthorized: true,
      disallowAuthorized: true,
      background: {
        backgroundImage: '/static/img/bg/default.jpg',
      },
    },
  },
  {
    path: '/typetuin-proberen',
    name: 'RegisterDemoUser',
    component: () =>
      import(
        /* webpackChunkName: "PasswordReset" */ '@/views/RegisterDemoUser.vue'
      ),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      allowUnauthorized: true,
      disallowAuthorized: true,
      background: {
        backgroundImage: '/static/img/bg/default.jpg',
      },
    },
  },
  {
    path: '/new-user',
    name: 'NewUser',
    component: () =>
      import(/* webpackChunkName: "PasswordReset" */ '@/views/NewUser.vue'),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      allowUnauthorized: true,
      disallowAuthorized: true,
      background: {
        backgroundImage: '/static/img/bg/default.jpg',
      },
    },
  },
  {
    path: '/login',
    name: LOGIN_ROUTE_NAME,
    component: () =>
      import(/* webpackChunkName: "Login" */ '@/views/Login.vue'),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      allowUnauthorized: true,
      disallowAuthorized: true,
      background: {
        backgroundImage: '/static/img/bg/default.jpg',
      },
    },
  },
  {
    path: '/overview',
    name: 'Overview',
    component: () =>
      import(/* webpackChunkName: "Overview" */ '@/views/Overview.vue'),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      background: {
        backgroundImage: '/static/img/bg/overview.jpg',
      },
      demoNotchPosition: 'top',
      allowDemoUser: true,
      routerClass: 'sb_route_overview',
    },
  },
  {
    path: '/information',
    name: 'Information',
    component: () =>
      import(
        /* webpackChunkName: "Overview" */ '@/views/Information/Information.vue'
      ),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      background: {
        backgroundImage: '/static/img/bg/default.jpg',
      },
      allowDemoUser: true,
    },
  },
  {
    path: '/videos/:category?',
    name: 'Videos',
    component: () =>
      import(
        /* webpackChunkName: "Overview" */ '@/views/Information/Videos.vue'
      ),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      background: {
        backgroundImage: '/static/img/bg/default.jpg',
      },
      allowDemoUser: false,
    },
  },
  {
    path: '/faq/:category?',
    name: 'FAQ',
    component: () =>
      import(/* webpackChunkName: "Overview" */ '@/views/Information/Faq.vue'),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      background: {
        backgroundImage: '/static/img/bg/default.jpg',
      },
      allowDemoUser: true,
    },
  },
  {
    path: '/progress',
    name: 'Progress',
    component: () =>
      import(/* webpackChunkName: "Overview" */ '@/views/Progress.vue'),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      background: {
        backgroundImage: '/static/img/bg/book_page-right.jpg',
      },
      allowDemoUser: true,
    },
  },
  // {
  //   path: '/achievements',
  //   name: 'Achievements',
  //   component: () => import(/* webpackChunkName: "Overview" */ '@/views/Achievements.vue'),
  //   meta: {
  //     background: {
  //       backgroundImage: '/static/img/bg/book_page-right.jpg'
  //     }
  //   }
  // },
  {
    path: '/kwouwsel',
    name: 'Kwouwsel',
    component: () =>
      import(
        /* webpackChunkName: "Overview" */ '@/views/Kwouwsel/Kwouwsel.vue'
      ),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      background: {
        backgroundImage: '/static/img/bg/bg_kwouwsel.jpg',
      },
      allowDemoUser: true,
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: "Overview" */ '@/views/Profile.vue'),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      background: {
        backgroundImage: '/static/img/bg/book_page-right.jpg',
      },
      allowDemoUser: true,
    },
  },
  {
    path: '/profile/password-reset',
    name: 'ProfilePasswordReset',
    component: () =>
      import(
        /* webpackChunkName: "Overview" */ '@/views/ProfilePasswordReset.vue'
      ),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      background: {
        backgroundImage: '/static/img/bg/book_page-right.jpg',
      },
    },
  },
  {
    path: '/introduction',
    name: 'Introduction',
    redirect: {
      name: 'Introduction 01',
    },
    component: () =>
      import(
        /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequenceIntroduction.vue'
      ),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      background: {
        backgroundImage: '/static/img/bg/default.jpg',
      },
      allowDemoUser: false,
    },
    children: [
      {
        path: '01',
        name: 'Introduction 01',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/01-welcome.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
          },
        },
      },
      {
        path: '02',
        name: 'Introduction 02',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/02-video.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
            backgroundOpacity: 0.3,
          },
        },
      },
      {
        path: '03',
        name: 'Introduction 03',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/03-type-your-name.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
          },
        },
      },
      {
        path: '04',
        name: 'Introduction 04',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/04-welcome-name.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
          },
        },
      },
      {
        path: '05',
        name: 'Introduction 05',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/05-first-challenge.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
          },
        },
      },
      {
        path: '06',
        name: 'Introduction 06',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/06-correct-position-video.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
            backgroundOpacity: 0.3,
          },
        },
      },
      {
        path: '07',
        name: 'Introduction 07',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/07-lesson1.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
          },
        },
      },
      {
        path: '08-01',
        name: 'Introduction 08-01',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/08-keyboard-base-row-01.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
          },
        },
      },
      {
        path: '08-02',
        name: 'Introduction 08-02',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/08-keyboard-base-row-02.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
          },
        },
      },
      {
        path: '08-03',
        name: 'Introduction 08-03',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/08-keyboard-base-row-03.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
          },
        },
      },
      {
        path: '08-04',
        name: 'Introduction 08-04',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/08-keyboard-base-row-04.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
          },
        },
      },
      {
        path: '08-05',
        name: 'Introduction 08-05',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/08-keyboard-base-row-05.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
          },
        },
      },
      {
        path: '08-06',
        name: 'Introduction 08-06',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/08-keyboard-base-row-06.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
          },
        },
      },
      {
        path: '09',
        name: 'Introduction 09',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/09-keyboard-challenge.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
            backgroundOpacity: 0.7,
          },
        },
      },
      {
        path: '10',
        name: 'Introduction 10',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/10-diamonds-introduction.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
          },
        },
      },
      {
        path: '11',
        name: 'Introduction 11',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/11-keyboard-challenge-diamonds.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
            backgroundOpacity: 0.7,
          },
        },
      },
      {
        path: '12',
        name: 'Introduction 12',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/12-diamonds-earned.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
          },
        },
      },
      {
        path: '13',
        name: 'Introduction 13',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/13-finger-positions-video.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
            backgroundOpacity: 0.3,
          },
        },
      },
      {
        path: '14',
        name: 'Introduction 14',
        component: () =>
          import(
            /* webpackChunkName: "IntroductionSequence" */ '@/views/PageSequences/Introduction/14-lets-start.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/bg/default.jpg',
          },
        },
      },
    ],
  },
  {
    path: '/level/:id',
    name: 'Level',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "PageSequenceLevelIntro" */ '@/views/PageSequenceLevelIntro.vue'
      ),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      background: {
        backgroundImage: '/static/img/bg/default.jpg',
      },
      allowDemoUser: true,
    },
    children: [
      // //////////////////////////////////////////////////
      //
      //    Generic introduction screens
      //
      // //////////////////////////////////////////////////
      {
        path: 'game',
        name: 'Level Game',
        component: () =>
          import(/* webpackChunkName: "LevelGame" */ '@/views/LevelGame.vue'),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            dynamicBackgroundImage:
              '/static/img/levels/bg_level-[id]_with-character.jpg',
            backgroundOpacity: 0.7,
          },
          allowDemoUser: true,
        },
      },
      {
        path: 'video',
        name: 'Level Video',
        component: () =>
          import(/* webpackChunkName: "LevelGame" */ '@/views/LevelVideo.vue'),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            dynamicBackgroundImage: '/static/img/levels/bg_level-[id].jpg',
            backgroundOpacity: 0.3,
          },
          allowDemoUser: true,
        },
      },
      {
        path: 'returnee',
        name: 'Level Returnee',
        component: () =>
          import(
            /* webpackChunkName: "LevelGame" */ '@/views/LevelReturnee.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            dynamicBackgroundImage: '/static/img/levels/bg_level-[id].jpg',
          },
          allowDemoUser: true,
        },
      },
      {
        path: 'call-friend',
        name: 'Level Call Friend',
        component: () =>
          import(
            /* webpackChunkName: "LevelGame" */ '@/views/LevelCallFriend.vue'
          ),
        beforeEnter: multiguard([generalGuard]),
        meta: {
          background: {
            dynamicBackgroundImage: '/static/img/levels/bg_level-[id].jpg',
          },
          allowDemoUser: true,
        },
      },
      // {
      //   path: 'posture',
      //   name: 'Posture',
      //   props: true,
      //   component: () =>
      //     import(/* webpackChunkName: "LevelGame" */ '@/views/Posture.vue'),
      //   meta: {
      //     background: {
      //       dynamicBackgroundImage: '/static/img/levels/bg_level-[id].jpg',
      //     },
      //     allowDemoUser: true,
      //   },
      // },
      // {
      //   path: 'ready',
      //   name: 'Ready',
      //   props: true,
      //   component: () =>
      //     import(/* webpackChunkName: "LevelGame" */ '@/views/Ready.vue'),
      //   meta: {
      //     background: {
      //       dynamicBackgroundImage: '/static/img/levels/bg_level-[id].jpg',
      //     },
      //     allowDemoUser: true,
      //   },
      // },

      // //////////////////////////////////////////////////
      //
      //    Level dependent introduction screens
      //
      // //////////////////////////////////////////////////
      ...Object.keys(LEVEL_INTRODUCTIONS).reduce<RouteConfig[]>(
        (acc, level) => {
          const introScreensCount: number = LEVEL_INTRODUCTIONS[Number(level)];

          const allowDemoUser = Number(level) === 1 || Number(level) === 8;

          return [
            ...acc,
            ...Array.from({ length: introScreensCount }, (_, index) => ({
              path: `${level}-intro-${index + 1}`,
              name: `Level ${level} Intro ${index + 1}`,
              component: () =>
                import(
                  /* webpackChunkName: "[request]" */ `@/views/PageSequences/Levels/Level${level}/${
                    index + 1
                  }-intro.vue`
                ),
              beforeEnter: multiguard([generalGuard]),
              meta: {
                background: {
                  backgroundImage: `/static/img/levels/bg_level-${level}_with-character.jpg`,
                },
                allowDemoUser,
              },
            })),
          ];
        },
        [],
      ),
    ],
  },
  {
    path: '/exam',
    name: 'Exam',
    redirect: {
      name: 'Exam Video',
    },
    component: () =>
      import(
        /* webpackChunkName: "ExamSequence" */ '@/views/PageSequenceExam.vue'
      ),
    beforeEnter: multiguard([generalGuard, examGuard]),
    children: [
      {
        path: 'video',
        name: 'Exam Video',
        component: () =>
          import(
            /* webpackChunkName: "ExamSequence" */ '@/views/PageSequences/Exam/01-video.vue'
          ),
        beforeEnter: multiguard([generalGuard, examGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/levels/bg_exam-intro.jpg',
            backgroundOpacity: 0.3,
          },
        },
      },
      {
        path: 'welcome',
        name: 'Exam Welcome',
        component: () =>
          import(
            /* webpackChunkName: "ExamSequence" */ '@/views/PageSequences/Exam/02-welcome.vue'
          ),
        beforeEnter: multiguard([generalGuard, examTriesGuard, examGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/levels/bg_exam-intro.jpg',
          },
        },
      },
      {
        path: 'explanation-1',
        name: 'Exam Explanation 1',
        component: () =>
          import(
            /* webpackChunkName: "ExamSequence" */ '@/views/PageSequences/Exam/03-explanation-1.vue'
          ),
        beforeEnter: multiguard([generalGuard, examTriesGuard, examGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/levels/bg_exam-intro.jpg',
          },
        },
      },
      {
        path: 'explanation-2',
        name: 'Exam Explanation 2',
        component: () =>
          import(
            /* webpackChunkName: "ExamSequence" */ '@/views/PageSequences/Exam/04-explanation-2.vue'
          ),
        beforeEnter: multiguard([generalGuard, examTriesGuard, examGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/levels/bg_exam-intro.jpg',
          },
        },
      },
      {
        path: 'exam-ready',
        name: 'Exam Ready',
        component: () =>
          import(
            /* webpackChunkName: "ExamSequence" */ '@/views/PageSequences/Exam/05-ready.vue'
          ),
        beforeEnter: multiguard([generalGuard, examTriesGuard, examGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/levels/bg_exam-intro.jpg',
          },
        },
      },
      {
        path: 'examination',
        name: 'Exam Examination',
        component: () =>
          import(
            /* webpackChunkName: "ExamSequence" */ '@/views/PageSequences/Exam/Examination.vue'
          ),
        beforeEnter: multiguard([generalGuard, examTriesGuard, examGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/levels/bg_exam-intro.jpg',
            backgroundOpacity: 0.7,
          },
        },
      },
      {
        path: 'maximum-tries',
        name: 'Exam Maximum Tries',
        component: () =>
          import(
            /* webpackChunkName: "ExamSequence" */ '@/views/PageSequences/Exam/MaximumTries.vue'
          ),
        beforeEnter: multiguard([
          generalGuard,
          examMaximumTriesGuard,
          examGuard,
        ]),
        meta: {
          background: {
            backgroundImage: '/static/img/levels/bg_exam-intro.jpg',
          },
        },
      },
    ],
  },
  {
    path: '/test-exam',
    name: 'Test Exam',
    redirect: {
      name: 'Test Exam Video',
    },
    component: () =>
      import(
        /* webpackChunkName: "TestExamSequence" */ '@/views/PageSequenceTestExam.vue'
      ),
    beforeEnter: multiguard([generalGuard, testExamGuard]),
    children: [
      {
        path: 'video',
        name: 'Test Exam Video',
        component: () =>
          import(
            /* webpackChunkName: "TestExamSequence" */ '@/views/PageSequences/TestExam/01-video.vue'
          ),
        beforeEnter: multiguard([generalGuard, testExamGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/levels/bg_test-exam-intro.jpg',
            backgroundOpacity: 0.3,
          },
        },
      },
      {
        path: 'welcome',
        name: 'Test Exam Welcome',
        component: () =>
          import(
            /* webpackChunkName: "TestExamSequence" */ '@/views/PageSequences/TestExam/02-welcome.vue'
          ),
        beforeEnter: multiguard([generalGuard, testExamGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/levels/bg_test-exam-intro.jpg',
          },
        },
      },
      {
        path: 'explanation-1',
        name: 'Test Exam Explanation 1',
        component: () =>
          import(
            /* webpackChunkName: "TestExamSequence" */ '@/views/PageSequences/TestExam/03-explanation-1.vue'
          ),
        beforeEnter: multiguard([generalGuard, testExamGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/levels/bg_test-exam-intro.jpg',
          },
        },
      },
      {
        path: 'explanation-2',
        name: 'Test Exam Explanation 2',
        component: () =>
          import(
            /* webpackChunkName: "TestExamSequence" */ '@/views/PageSequences/TestExam/04-explanation-2.vue'
          ),
        beforeEnter: multiguard([generalGuard, testExamGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/levels/bg_test-exam-intro.jpg',
          },
        },
      },
      {
        path: 'explanation-3',
        name: 'Test Exam Explanation 3',
        component: () =>
          import(
            /* webpackChunkName: "TestExamSequence" */ '@/views/PageSequences/TestExam/05-explanation-3.vue'
          ),
        beforeEnter: multiguard([generalGuard, testExamGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/levels/bg_test-exam-intro.jpg',
          },
        },
      },
      {
        path: 'examination',
        name: 'Test Exam Examination',
        component: () =>
          import(
            /* webpackChunkName: "TestExamSequence" */ '@/views/PageSequences/TestExam/Examination.vue'
          ),
        beforeEnter: multiguard([generalGuard, testExamGuard]),
        meta: {
          background: {
            backgroundImage: '/static/img/levels/bg_test-exam-intro.jpg',
            backgroundOpacity: 0.7,
          },
        },
      },
    ],
  },
  {
    path: '/course-completed',
    name: 'CourseCompleted',
    component: () =>
      import(
        /* webpackChunkName: "CourseCompleted" */ '@/views/CourseCompleted.vue'
      ),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      background: {
        backgroundImage: '/static/img/bg/default.jpg',
        backgroundOpacity: 1,
      },
    },
  },
  // {
  //   path: '/test/exam-result',
  //   name: 'ExamResult',
  //   component: () => import(/* webpackChunkName: "Test/ExamResult" */ '@/views/test/ExamResult.vue'),
  //   meta: {
  //     allowUnauthorized: true,
  //     background: {
  //       backgroundImage: '/static/img/levels/bg_test-exam-intro.jpg',
  //       backgroundOpacity: 0.7
  //     }
  //   }
  // },
  {
    path: '/404',
    name: 'PageNotFound',
    component: () =>
      import(/* webpackChunkName: "PageNotFound" */ '@/views/PageNotFound.vue'),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      allowUnauthorized: true,
      background: {
        backgroundImage: '/static/img/bg/default.jpg',
      },
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'UnknownPage',
    component: () =>
      import(/* webpackChunkName: "PageNotFound" */ '@/views/PageNotFound.vue'),
    beforeEnter: multiguard([generalGuard]),
    meta: {
      allowUnauthorized: true,
      background: {
        backgroundImage: '/static/img/bg/default.jpg',
      },
    },
  },
];

export const router = new VueRouter({
  routes,
});
