<template>
  <div
    class="sb_scene"
    style="transform: translate(-50%, -50%) scale(3)"
    :style="{
      transform: 'translate(-50%, -50% ) translateZ(0) scale(' + scale + ')',
    }">
    <div class="sb_scene_content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    scale: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss">
.sb_scene {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;

  // SHOW SAFE BORDER WITHIN SCENE
  // box-shadow: inset 0px 0px 0px 4px red;
  z-index: 3;
  width: 1280px;
  height: 960px;
  backface-visibility: hidden;

  @media print {
    position: relative;
    top: inherit;
    left: inherit;
    width: inherit;
    height: inherit;
    transform: none !important;
  }

  &_content {
    position: absolute;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    margin: 20px;
  }

  &.s_fade-elems {
    .sb_page-sequence_title,
    .sb_btn-back-to-overview,
    .sb_btn-to-level,
    .sb_page-sequence_navigation_left {
      opacity: 0.4;
      transition: opacity 0.3s ease;
    }

    .sb_progressbar {
      opacity: 0.4;
    }
  }
}
</style>
