import { LS_ACCESS_TOKEN, LS_REFRESH_TOKEN } from './constants';

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export function getAccessToken() {
  if (typeof localStorage === 'undefined') {
    return null;
  }

  return localStorage.getItem(LS_ACCESS_TOKEN);
}

export function getRefreshToken() {
  if (typeof localStorage === 'undefined') {
    return null;
  }

  return localStorage.getItem(LS_REFRESH_TOKEN);
}

export function setAccessToken(token) {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(LS_ACCESS_TOKEN, token);
  }
}

export function setRefreshToken(refreshToken) {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(LS_REFRESH_TOKEN, refreshToken);
  }
}

export function setExpiresIn(timeStamp) {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('token-expiration', timeStamp);
  }
}

export function isTokenExpired() {
  const token = getAccessToken();
  if (!token) return true;

  const expiration = parseInt(parseJwt(token).exp);
  const now = Math.floor(new Date().getTime() / 1000);
  if (now <= expiration) {
    return false;
  }
  return true;
}

export function getRole() {
  const token = getAccessToken();
  if (!token) return null;

  return parseJwt(token).role;
}

export function getSchool() {
  const token = getAccessToken();
  if (!token) return null;

  return parseJwt(token).schoolId;
}

export function getId() {
  const token = getAccessToken();
  if (!token) return null;

  return parseJwt(token).sub;
}
