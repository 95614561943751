import { NavigationGuard } from 'vue-router';
import { apollo } from '@/apollo';
import QueryLevel from '@/graphql/exam/queries/Me.gql';
import { MAXIMUM_EXAM_TRIES } from '@/lib/constants';
import { getLevelLockStates } from '@/lib/helpers';

export const examGuard: NavigationGuard = async (_to, _from, next) => {
  const result = await apollo.defaultClient.query({
    query: QueryLevel,
    fetchPolicy: 'network-only',
  });

  // Redirect to overview if exam is not unlocked yet
  const levelUnlockStates = getLevelLockStates(
    result?.data?.me?.typetuin?.userLevels,
    result?.data?.me?.typetuin?.usePersonalLevelSettings,
    result?.data?.me?.typetuin?.followingCourseGroup?.levelSettings,
    result?.data?.me?.typetuin?.followingCourseGroup?.useLevelSettings,
    result?.data?.me?.typetuin?.gameCompleted,
  );

  // @ts-expect-error Not typed
  if (!levelUnlockStates.examen)
    return next({ name: 'Overview', replace: true });

  return next();
};

export const examTriesGuard: NavigationGuard = async (_to, _from, next) => {
  const result = await apollo.defaultClient.query({
    query: QueryLevel,
  });

  const tries = result?.data?.me?.typetuin?.examRequests?.length ?? 0;
  const hadMaximumTries = tries >= MAXIMUM_EXAM_TRIES;

  if (hadMaximumTries) return next({ name: 'Exam Maximum Tries' });

  return next();
};

export const examMaximumTriesGuard: NavigationGuard = async (
  _to,
  _from,
  next,
) => {
  const result = await apollo.defaultClient.query({
    query: QueryLevel,
  });

  const tries = result?.data?.me?.typetuin?.examRequests?.length ?? 0;
  const hadMaximumTries = tries >= MAXIMUM_EXAM_TRIES;

  if (!hadMaximumTries) return next({ name: 'Exam' });

  return next();
};

export const testExamGuard: NavigationGuard = async (_to, _from, next) => {
  const result = await apollo.defaultClient.query({
    query: QueryLevel,
    fetchPolicy: 'network-only',
  });

  // Redirect to overview if exam is not unlocked yet
  const levelUnlockStates = getLevelLockStates(
    result?.data?.me?.typetuin?.userLevels,
    result?.data?.me?.typetuin?.usePersonalLevelSettings,
    result?.data?.me?.typetuin?.followingCourseGroup?.levelSettings,
    result?.data?.me?.typetuin?.followingCourseGroup?.useLevelSettings,
    result?.data?.me?.typetuin?.gameCompleted,
  );

  // @ts-expect-error Not typed
  if (!levelUnlockStates.proefexamen)
    return next({ name: 'Overview', replace: true });

  return next();
};
