import type { default as VueRouter } from 'vue-router';
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import { GTM } from '@/lib/env';

export function setupGTM(router: VueRouter) {
  if (!GTM) return;

  Vue.use(
    VueGtag,
    {
      config: {
        id: GTM,
      },
    },
    router,
  );
}
