<template>
  <div class="sb_svg-icon">
    <svg role="img">
      <use :xlink:href="'#' + iconId" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    iconId: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_svg-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform-origin: 50% 50%;

  svg {
    width: 1em;
    height: 1em;
    fill: currentcolor;
  }
}
</style>
