import { createPinia, PiniaVuePlugin } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import Vue from 'vue';

Vue.use(PiniaVuePlugin);

export const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);

// Hot updates (Seems to be broken)
// if (module.hot) {
//   module.hot.accept(acceptHMRUpdate(useMainStore, module.hot));
// }
