<template>
  <div
    class="sb_demo"
    :class="{
      'v_notch-position_top': notchPosition === 'top',
      'v_notch-position_bottom': notchPosition === 'bottom',
    }">
    <div class="sb_demo_border-top" />
    <div class="sb_demo_border-right" />
    <div class="sb_demo_border-bottom" />
    <div class="sb_demo_border-left" />
    <div class="sb_demo_notch" @click="$emit('info')">
      <div class="sb_demo_notch_text">Demo modus</div>
      <div class="sb_demo_notch_icon">
        <SvgIcon icon-id="icon_info" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Demo',
  props: {
    notchPosition: {
      type: String,
      default: 'top',
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_demo {
  //
  // NOTCH POSITION
  //

  &.v_notch-position_bottom {
    .sb_demo_notch {
      top: inherit;
      bottom: 0;
      padding: 15px 15px 20px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  //
  // BORDERS
  //

  &_border-top {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 10px;
    pointer-events: none;
    background: $brand-yellow;
  }

  &_border-right {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
    width: 10px;
    height: 100%;
    pointer-events: none;
    background: $brand-yellow;
  }

  &_border-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 10px;
    pointer-events: none;
    background: $brand-yellow;
  }

  &_border-left {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 10px;
    height: 100%;
    pointer-events: none;
    background: $brand-yellow;
  }

  //
  // NOTCH
  //

  &_notch {
    position: absolute;
    right: -90px;
    z-index: 9999;
    display: flex;
    align-self: center;
    padding: 20px 15px 15px;
    background: $brand-yellow;
    border-top: 0;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    transform: translateX(-50%);

    &_text {
      margin: 0 10px 0 0;
      font-size: 20px;
      font-weight: bold;
    }

    &_icon {
      display: flex;
      flex-direction: column;
      align-self: center;
      font-size: 22px;
      opacity: 0.3;
      transition: opacity 0.3s ease;
    }

    &:hover {
      cursor: pointer;

      .sb_demo_notch_icon {
        opacity: 1;
      }
    }
  }
}
</style>
