import { Locale } from '@/types/export';
import { GqlKeyboardLayout } from '@/types/graphql';
import { DEV_MODE } from './env';

export const IS_DEV_MODE = DEV_MODE === 'true';
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const HAS_LOCALSTORAGE = typeof localStorage !== 'undefined';

export const DEFAULT_LOCALE = Locale.nl;
export const LOCALES = [Locale.nl];
export const DEFAULT_KEYBOARD_LAYOUT = GqlKeyboardLayout.QWERTY;
export const KEYBOARD_LAYOUTS = [
  GqlKeyboardLayout.QWERTY,
  GqlKeyboardLayout.AZERTY,
];

export const DEMO_STUDENT_ROLE = 'DEMO_STUDENT';

export const LS_ACCESS_TOKEN = 'apollo-token';
export const LS_REFRESH_TOKEN = 'apollo-refresh-token';

export const MAXIMUM_EXAM_TRIES = 3;
export const MAXIMUM_MINUTES_PER_LEVEL = 15;

export const LEVEL_INTRODUCTIONS: Record<number, number> = {
  1: 4,
  2: 5,
  3: 3,
  4: 3,
  5: 4,
  6: 4,
  7: 3,
  8: 4,
  9: 1,
};
