






















import type { RouteConfig } from 'vue-router';
import { defineComponent } from '@vue/composition-api';
import { mapStores } from 'pinia';
import { KEYBOARD_LAYOUTS } from '@/lib/constants';
import { routes } from '@/router';
import { useMainStore } from '@/stores/main';

type Data = {
  routes: RouteConfig[];
  showDevMenu: boolean;
  KEYBOARD_LAYOUTS: typeof KEYBOARD_LAYOUTS;
};

export default defineComponent({
  name: 'TtDevMenu',
  data(): Data {
    return {
      routes,
      showDevMenu: false,
      KEYBOARD_LAYOUTS,
    };
  },
  computed: {
    ...mapStores(useMainStore),
  },
  created() {
    window.addEventListener('keydown', this.onKeyboardInput);
  },
  destroyed() {
    window.removeEventListener('keydown', this.onKeyboardInput);
  },
  methods: {
    onKeyboardInput(event: KeyboardEvent) {
      if (event.metaKey && event.key === 'k') {
        this.showDevMenu = !this.showDevMenu;
      }

      if (this.showDevMenu && event.metaKey && event.key === 'l') {
        this.mainStore.keyboardLayout =
          KEYBOARD_LAYOUTS[
            (KEYBOARD_LAYOUTS.indexOf(this.mainStore.keyboardLayout) + 1) %
              KEYBOARD_LAYOUTS.length
          ];
      }
    },
  },
});
