


































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { ApolloQueryResult } from '@apollo/client/core';
import * as Sentry from '@sentry/vue';
import { defineComponent } from '@vue/composition-api';
import { gql } from 'graphql-tag';
import { mapStores } from 'pinia';
import { graphqlErrorHandler, queueLink } from '@/apollo';
import Background from '@/components/Background.vue';
import DemoBorder from '@/components/DemoBorder.vue';
import DevMenu from '@/components/DevMenu.vue';
import ErrorBoundary from '@/components/ErrorBoundary.vue';
import IdleListener from '@/components/IdleListener.vue';
import ModalError from '@/components/ModalError.vue';
import ModalMessage from '@/components/ModalMessage.vue';
import Scene from '@/components/Scene.vue';
import Symbols from '@/components/SvgSymbols.vue';
import meQuery from '@/graphql/auth/queries/Me.gql';
import { APP_VERSION } from '@/lib/env';
import { date } from '@/lib/filters/date';
import { useMainStore } from '@/stores/main';
import { GqlUser } from '@/types/graphql';
import { DEMO_STUDENT_ROLE } from './lib/constants';
import { getAccessToken, getRole } from './lib/util-helper';

type ApolloResultKeyboardLayout = {
  me: Pick<GqlUser, 'id' | 'keyboardLayout'>;
};

export default defineComponent({
  name: 'TtApp',
  components: {
    DemoBorder,
    Background,
    DevMenu,
    Scene,
    Symbols,
    ErrorBoundary,
    IdleListener,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    // all titles will be injected into this template
    titleTemplate: 'Typetuin - %s',
  },
  data() {
    return {
      version: APP_VERSION,
      scale: 1,
      boundaryError: '',
      fadeElems: false,
      me: null,
    };
  },
  apollo: {
    me: {
      skip() {
        return !getAccessToken();
      },
      query: meQuery,
      update(data) {
        return data?.me;
      },
      result(result) {
        if (!result?.data?.me) {
          return;
        }

        Sentry.setUser({
          id: result.data.me.id,
          username: result.data.me.username,
        });
      },
      error() {
        Sentry.setUser(null);
      },
    },
    keyboardLayout: {
      skip() {
        return !this.mainStore.isLoggedIn;
      },
      query: gql`
        query MyKeyboardLayout {
          me {
            id
            keyboardLayout
          }
        }
      `,
      update(data: ApolloResultKeyboardLayout) {
        return data?.me?.keyboardLayout;
      },
      result(result: ApolloQueryResult<ApolloResultKeyboardLayout>) {
        this.mainStore.keyboardLayout = result?.data?.me?.keyboardLayout;
      },
      fetchPolicy: 'cache-and-network',
    },
  },
  created() {
    // Set initial locale
    this.$i18n.locale = this.mainStore.locale;

    this.checkAndSetVariables();
  },
  async mounted() {
    console.info('Typetuin Game version', this.version);
    this.$nextTick(() => {
      window.addEventListener('resize', this.setbackgroundScale);
      this.setbackgroundScale();
    });

    graphqlErrorHandler(this.handleGraphqlError);

    window.addEventListener('offline', this.handleNetwork);
    window.addEventListener('online', this.handleNetwork);
  },
  async destroyed() {
    window.removeEventListener('offline', this.handleNetwork);
    window.removeEventListener('online', this.handleNetwork);
  },
  computed: {
    ...mapStores(useMainStore),
    hasDemoNotch() {
      return !!this.demoNotchPosition;
    },
    demoNotchPosition(): string | undefined {
      if (!this.mainStore.isDemoUser || this.$route.meta.allowUnauthorized)
        return;

      if (this.$route.meta.demoNotchPosition)
        return this.$route.meta.demoNotchPosition;

      return 'top';
    },
    focusMode() {
      if (!this.me || !this.me.typetuin) return false;
      return (
        this.me.typetuin.focusMode &&
        ['Level Game', 'Exam Examination', 'Test Exam Examination'].includes(
          this.$route.name,
        )
      );
    },
    backgroundImage() {
      // use props to replace values in dynamicBackgroundImage
      if (
        this.$route.meta &&
        this.$route.meta.background &&
        this.$route.meta.background.dynamicBackgroundImage
      ) {
        const route = Object.keys(this.$route.params).reduce((prev, key) => {
          if (
            this.$route.meta.background.dynamicBackgroundImage.includes(
              `[${key}]`,
            )
          ) {
            return this.$route.meta.background.dynamicBackgroundImage.replace(
              `[${key}]`,
              this.$route.params[key],
            );
            // return prev;
          }
          return prev;
        }, '');
        return route;
      }

      if (
        !this.$route.meta ||
        !this.$route.meta.background ||
        !this.$route.meta.background.backgroundImage
      ) {
        return undefined;
      }
      return this.$route.meta.background.backgroundImage;
    },
    backgroundOpacity() {
      if (
        !this.$route.meta ||
        !this.$route.meta.background ||
        !this.$route.meta.background.backgroundOpacity
      ) {
        return undefined;
      }
      return this.$route.meta.background.backgroundOpacity;
    },
    routerClass() {
      if (!this.$route.meta || !this.$route.meta.routerClass) return undefined;
      return this.$route.meta.routerClass;
    },
  },
  watch: {
    backgroundOpacity(value) {
      if (value < 0.5) {
        this.fadeElems = true;
      } else {
        this.fadeElems = false;
      }
    },
    '$route.fullPath'() {
      this.checkAndSetVariables();
    },
    '$i18n.locale'(newValue) {
      // Sync $i18n to mainStore
      if (newValue !== this.mainStore.locale) this.mainStore.locale = newValue;
    },
    'mainStore.locale'(newValue) {
      // Sync mainStore to $i18n
      if (newValue !== this.$i18n.locale) this.$i18n.locale = newValue;
    },
  },
  methods: {
    checkAndSetVariables() {
      // We use this function to make these variables 'reactive' by performing this function when navigation occurs
      this.mainStore.isLoggedIn = !!getAccessToken();
      this.mainStore.isDemoUser = getRole() === DEMO_STUDENT_ROLE;
    },
    demoInfo() {
      this.$nextTick(() => {
        this.$modal.show(
          ModalMessage,
          {
            title: 'Welkom in de Typetuin!',
            bodyText:
              'Leuk dat je een kijkje komt nemen! In deze demoversie kun je level 1, level 8 en één bonuslevel spelen. In de demoversie zijn niet alle functionaliteiten beschikbaar en ook je voortgang wordt niet bijgehouden, maar je kunt rustig even rondkijken. Wil je meer weten, ga dan naar <a target="_blank" href="https://typetuin.nl/aanmelden">typetuin.nl</a>',
            primaryText: 'Doorgaan',
          },
          {
            width: 600,
            height: 'auto',
          },
        );
      });
    },
    logout() {
      this.mainStore.logoutUser();
    },
    handleGraphqlError(error) {
      if (error.graphQLErrors) {
        const status =
          error.graphQLErrors[0].extensions &&
          error.graphQLErrors[0].extensions.exception
            ? error.graphQLErrors[0].extensions.exception.status
            : null;
        const { query, code, exception } = error.graphQLErrors[0].extensions;
        const { message } = error.graphQLErrors[0];
        const errorCode = exception.response.error;

        console.log(error.graphQLErrors[0].extensions);
        console.log(
          'error message:',
          message,
          'error status',
          status,
          'code',
          code,
          'error code',
          errorCode,
        );

        if (code === 'TEMP_PASSWORD' && status === 206) {
          return this.$modal.show(
            ModalMessage,
            {
              title: 'Tijdelijk wachtwoord',
              bodyText:
                'Je hebt een tijdelijk wachtwoord. Je wordt naar een pagina geleid om dit naar een wachtwoord naar keuze te veranderen.',
              primaryText: 'OK',
              primaryIcon: false,
              primaryCallback() {
                this.$router.push({
                  name: 'NewPassword',
                  query: {
                    token: exception.response.token,
                    user_id: exception.response.userId,
                  },
                });
              },
            },
            {
              width: 600,
              height: 'auto',
            },
          );
        }

        console.log({
          code,
          errorCode,
        });

        const errorMap = {
          // START_DATE_IN_FUTURE: 'De startdatum van de cursus ligt in de toekomst. Probeer het later nog eens.',
          END_DATE_IN_PAST:
            'De eindatum van je cursus is verstreken. Je kunt niet meer inloggen.',
          NO_LICENSE:
            'Er is op dit moment geen licentie gekoppeld aan je account. Neem contact op met de beheerder.',
          PASSWORD_NOT_SET:
            'Voltooi eerst de stappen in de registratie e-mail die je ontvangen hebt, of vraag een beheerder je wachtwoord in te stellen.',
          SCHOOL_DEACTIVATED: 'De school is niet meer actief',
        };

        if (code === 'INVALID_CREDENTIALS') {
          return this.$modal.show(
            ModalMessage,
            {
              title: 'Oeps.',
              bodyText:
                'Je gebruikersnaam en / of wachtwoord kloppen niet. Vul je juiste gebruikersnaam en wachtwoord in.',
              primaryText: 'OK',
              primaryIcon: false,
            },
            {
              height: 350,
            },
          );
        }

        if (errorCode === 'START_DATE_IN_FUTURE') {
          console.log(error);
          return this.$modal.show(
            ModalMessage,
            {
              title: 'Startdatum in de toekomst.',
              bodyText: `De startdatum van de cursus ligt in de toekomst. Je kunt inloggen vanaf${
                exception.startDate
                  ? ` ${date(new Date(exception.startDate))}`
                  : ''
              }.`,
              primaryText: 'OK',
              primaryIcon: false,
            },
            {
              height: 350,
            },
          );
        }

        if (errorMap[errorCode]) {
          return this.showErrorModal(errorMap[errorCode]);
        }
        if (errorMap[code]) {
          return this.showErrorModal(errorMap[code]);
        }

        if (message === 'Invalid token') {
          return this.showErrorModal(
            'Het lijkt er op dat de token ongeldig is of al gebruikt is. Als er al een wachtwoord is aangemaakt kun je hiermee inloggen. Als dit probleem zich blijft voordoen neem dan contact op met Typetuin.',
          );
        }

        if (message === 'Invalid license') {
          return this.showErrorModal(
            'Het lijkt er op dat je geen gekoppelde licentie hebt, of dat deze is verlopen. Neem contact op met je school of met Typetuin.',
          );
        }

        if (message === 'Rate limit exceeded') {
          return this.$modal.show(
            ModalMessage,
            {
              title: 'Maximum aantal proeflessen vandaag bereikt.',
              bodyText:
                'Uit veiligheidsoverwegingen kun je per IP adres maximaal 5 proeflessen per dag doen.',
              primaryText: 'OK',
              primaryIcon: false,
            },
            {
              height: 350,
            },
          );
        }

        if (status === 403) {
          if (message.includes('Not allowed just yet')) {
            const date = message.split('date: ')[1];
            return this.showErrorModal(
              `Je account is nog niet actief, vanaf ${date(
                new Date(date),
                'dd-MM-yyyy',
              )} kun je inloggen`,
              false,
            );
          }
        }

        console.error(message);

        if (
          message ===
          'Unexpected error value: "The challenge request has been expired."'
        ) {
          return false;
        }

        switch (status) {
          case 401:
            return this.logout();
          case 404:
            if (query === '{me{__typename id}}') {
              console.log(
                'User not found. This can happen when you have a valid token, but the user is not in current database',
              );
            }
          // eslint-disable-next-line no-fallthrough
          default:
            return this.showErrorModal(message);
        }
      }

      if (error.networkError) {
        console.error('Network error', {
          error: JSON.stringify(error),
          networkError: JSON.stringify(error.networkError),
          operation: JSON.stringify(error.operation),
          operationName: error.operation.operationName,
          variables: JSON.stringify(error.operation.variables),
        });

        if (error.networkError.message === 'Failed to fetch') {
          console.info('Network error: Failed to fetch');
          return this.showErrorModal(
            'Het lijkt erop dat er geen connectie kan worden gemaakt met de server. Controleer uw internetverbinding en probeer het opnieuw.',
          );
        }

        if (RegExp(/5[0-9][0-9]/).test(String(error.networkError.statusCode))) {
          console.info('Network error: 5xx');
          return this.showErrorModal(
            `Het lijkt erop dat er problemen zijn met de server. Probeer het later nog eens. ${error.networkError}`,
          );
        }

        return this.showErrorModal(error.networkError.message);
      }

      return false;
    },
    setbackgroundScale() {
      const windowWidth = document.documentElement.clientWidth;
      const windowHeight = document.documentElement.clientHeight;

      let calculatedScale = Math.min(windowWidth / 1280, windowHeight / 960);
      // only scale down?
      if (calculatedScale > 1.2) calculatedScale = 1.2;
      this.scale = calculatedScale;
    },
    showErrorModal(message, showContactInfo = true) {
      this.$modal.hide('errorModal');
      // this.boundaryError = message;
      this.$modal.show(
        ModalError,
        {
          error: message,
          showContactInfo,
        },
        {
          width: 600,
          height: 'auto',
          name: 'errorModal',
        },
      );
    },
    handleBoundaryError({ err }) {
      // Handle GraphQL and Network errors seperately in graphqlErrorHandler
      if (
        err.message.includes('GraphQL error') ||
        err.message.includes('Network error')
      ) {
        return;
      }
      console.error('Boundary error', err);

      this.showErrorModal(err);
    },
    handleNetwork(_event: Event) {
      switch (navigator.onLine) {
        case true:
        default:
          this.$modal.hide('offlineModal');
          queueLink.open();
          return;
        case false:
          queueLink.close();
          return;
      }
    },
  },
});
