import { defineComponent } from '@vue/composition-api';
import { default as screenfull } from 'screenfull';

/**
 * This isn't correctly typed as a mixin, so return types etc. are documented within `shims-tsx.d.ts`
 */
export default defineComponent({
  name: 'FullscreenMixin',
  data() {
    return {
      isFullscreen: false,
    };
  },
  created() {
    if (this.isFullscreenEnabled)
      screenfull.on('change', () => {
        this.isFullscreen = !this.isFullscreen;
      });
  },
  computed: {
    isFullscreenEnabled() {
      return !!screenfull && !!screenfull.isEnabled;
    },
  },
  methods: {
    toggleFullscreen(): void {
      if (this.isFullscreenEnabled) screenfull.toggle();
    },
  },
});
