import PortalVue from 'portal-vue';
import Vue from 'vue';
import '@/lib/mixins';
import '@/components';
import '@/modal';
import '@/scroll-to';
import { apollo } from '@/apollo';
import App from '@/App.vue';
import { setupGTM } from '@/gtm';
import { i18n } from '@/i18n';
import { pinia } from '@/pinia';
import { router } from '@/router';
import { setupSentry } from '@/sentry';

setupSentry(router);
setupGTM(router);

Vue.use(PortalVue);

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  pinia,
  apolloProvider: apollo,
  render: (h) => h(App),
}).$mount('#app');
