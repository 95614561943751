/* eslint-disable indent */
// maps keyboard.json to colour per key
// output:
// {
//   ...
//   a: "rgb(249, 205, 61",
//   s: "rgb(165, 220, 50)",
//   d: "rgb(255, 134, 70)",
//   ...
// }

import levelsData from '@/data/levels.json';

const alternatives = {
  e: ['é', 'ë', 'ê', 'è'],
  a: ['á', 'ä', 'â', 'à'],
  i: ['í', 'ï', 'ì', 'î'],
  o: ['ó', 'ö', 'ô', 'ò'],
  u: ['ú', 'ü', 'û', 'ù'],
};

export const keyColourConverter = (keyboard, groupColourMap) => {
  const keyColourMapping = {};
  keyboard.forEach((row) => {
    row.forEach((char) => {
      if (alternatives[char.default]) {
        alternatives[char.default].forEach((alternativeChar) => {
          keyColourMapping[alternativeChar] = groupColourMap[char.group];
        });
      }
      if (char.group && char.default) {
        keyColourMapping[char.default] = groupColourMap[char.group];
        keyColourMapping[char.default.toUpperCase()] =
          groupColourMap[char.group];
      }
      if (char.group && char.shift) {
        keyColourMapping[char.shift] = groupColourMap[char.group];
        keyColourMapping[char.shift.toUpperCase()] = groupColourMap[char.group];
      }
    });
  });
  return keyColourMapping;
};

export const getLevelLockStates = (
  userLevels,
  usePersonalSettings,
  levelSettings,
  useCourseGroupSettings,
  gameCompleted = false,
  kwouwsel = false,
) => {
  const levelSlugMap = levelsData.map((levelData) => levelData.id);

  if (gameCompleted) {
    const unlockedLevelsOnGameCompleted = kwouwsel
      ? [
          'level-1',
          'level-2',
          'level-3',
          'level-4',
          'level-5',
          'level-6',
          'level-7',
          'level-8',
          'level-9',
        ]
      : ['level-6', 'level-7', 'level-8', 'level-9'];
    return levelSlugMap.reduce((prev, cur) => {
      prev[cur] = unlockedLevelsOnGameCompleted.includes(cur);
      return prev;
    }, {});
  }

  const lockedStates = levelSlugMap.reduce((prev, cur) => {
    const userLevel = userLevels.find(
      (_userLevel) => _userLevel.level.id === cur,
    );
    const groupUserLevel = useCourseGroupSettings
      ? levelSettings.find((levelSetting) => levelSetting.level === cur)
      : false;
    prev[cur] = userLevel.unlocked;

    // if personal setting, return personal settings
    if (usePersonalSettings) {
      prev[cur] = userLevel.unlocked;
      if (userLevel.state === 'Disabled') prev[cur] = false;
      if (userLevel.state === 'Enabled') prev[cur] = true;
      return prev;
    }
    // if group setting, return personal settings
    if (useCourseGroupSettings) {
      prev[cur] = userLevel.unlocked;
      if (groupUserLevel && groupUserLevel.state === 'Disabled') {
        prev[cur] = false;
      }
      if (groupUserLevel && groupUserLevel.state === 'Enabled') {
        prev[cur] = true;
      }
      return prev;
    }

    // if personal setting or group level setting is not set, return normal setting
    return prev;
  }, {});
  return lockedStates;
};

export default {
  keyColourConverter,
};
