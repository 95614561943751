import { GqlKeyboardLayout } from './graphql';

export enum Locale {
  'nl' = 'nl',
  // 'en' = 'en',
  // 'fr' = 'fr',
}

export type KeyboardLayoutLookup<T> = {
  [GqlKeyboardLayout.QWERTY]: T;
  [GqlKeyboardLayout.AZERTY]?: T;
};

export type LocaleLookup<T> = {
  [Locale.nl]: T;
  // [Locale.en]?: T;
  // [Locale.fr]?: T;
};

export type LocaleKeyboardLayoutLookup<T> = {
  [Locale.nl]: KeyboardLayoutLookup<T> | T;
  // [Locale.en]?: KeyboardLayoutLookup<T> | T;
  // [Locale.fr]?: KeyboardLayoutLookup<T> | T;
};
