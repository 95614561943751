import Vue from 'vue';
import VModal from 'vue-js-modal';

Vue.use(VModal, {
  dialog: true,
  dynamic: true,
  dynamicDefaults: {
    clickToClose: false,
  },
});
