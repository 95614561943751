// General
export const NODE_ENV = process.env.VUE_APP_NODE_ENV;
export const RELEASE_GIT_SHORT_SHA = process.env.VUE_APP_RELEASE_GIT_SHORT_SHA;
export const APP_VERSION = process.env.VUE_APP_VERSION;
export const DEV_MODE = process.env.VUE_APP_DEV_MODE;

// GraphQL
export const GRAPHQL_HTTP = process.env.VUE_APP_GRAPHQL_HTTP;
export const WORDPRESS_GRAPHQL_HTTP =
  process.env.VUE_APP_WORDPRESS_GRAPHQL_HTTP;
export const FILES_ROOT = process.env.VUE_APP_FILES_ROOT;

// Google Tag Manager
export const GTM = process.env.VUE_APP_GTM;

// Sentry
export const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;
export const SENTRY_TRACES_SAMPLE_RATE =
  process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE;
export const SENTRY_LOG_LEVEL = process.env.VUE_APP_SENTRY_LOG_LEVEL;

// Minigames
export const MINIGAME_1_URL = process.env.VUE_APP_MINIGAME_1_URL;
export const MINIGAME_2_URL = process.env.VUE_APP_MINIGAME_2_URL;
export const MINIGAME_3_URL = process.env.VUE_APP_MINIGAME_3_URL;
